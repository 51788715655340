import React from "react";
import styled from "styled-components";
// Components
import ProjectBox from "../Elements/ProjectBox";
import FullButton from "../Buttons/FullButton";
// Assets
import ProjectImg1 from "../../assets/img/projects/1.png";
import ProjectImg2 from "../../assets/img/projects/2.png";
import ProjectImg3 from "../../assets/img/projects/3.png";
import ProjectImg4 from "../../assets/img/projects/4.png";
import ProjectImg5 from "../../assets/img/projects/5.png";
import ProjectImg6 from "../../assets/img/projects/6.png";
import ProjectImg7 from "../../assets/img/projects/7.png";
import ProjectImg8 from "../../assets/img/projects/8.png";
import AddImage2 from "../../assets/img/add/add2.png";

export default function Projects() {
  return (
    <Wrapper id="projects">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Our Ongoing Projects</h1>
            <p className="font13">
              {/* Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
              <br />
              labore et dolore magna aliquyam erat, sed diam voluptua. */}
              We are continuously working on innovative tools and resources to support the indigenous languages. From dictionaries and language keyboards to number conversion libraries and document parsers, our projects aim to make these languages more accessible and easier to use in the digital world. Explore our latest initiatives below!
            </p>
          </HeaderInfo>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <ProjectBox
                img={ProjectImg1}
                title="Gafargan Dictionary"
                text="A dictionary website featuring Lezgi and Tabasaran languages."
                action={() => window.open('https://gafargan.com', '_blank')}
              />
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6" >
              <ProjectBox
                img={ProjectImg2}
                title="Telegram Dictionary"
                text="This Telegram bot contains the same content as the website, but in a more convenient format for telegram users."
                action={() => window.open('https://t.me/GafarganBot', '_blank')}
              />
            </div>
          </div>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <ProjectBox
                img={ProjectImg3}
                title="Lezgi Keyboard"
                text="An iOS keyboard for the Lezgi language."
                action={() => window.open('https://apps.apple.com/us/app/id6444746265', '_blank')}
              />
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6" >
              <ProjectBox
                img={ProjectImg6}
                title={(<span>Mobile offline dictionary apps (<a href="https://apps.apple.com/es/app/gafargan/id6736903871?l=en-GB&platform=iphone" target="_blank"><u>iOS</u></a> and <a href="https://play.google.com/store/apps/details?id=io.lekitech.gafargan" target="_blank"><u>Android</u></a>)</span>)}
                // title="Mobile offline dictionary apps (Android and iOS)"
                text="These apps are open source offline dictionaries of minority languages. At the moment there is support for Lezgi and Tabasaran languages."
                // action={() => {}}
              />
            </div>
          </div>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <ProjectBox
                img={ProjectImg7}
                title="IT courses in native languages"
                text="This project is a work in progress dedicated to creating IT courses in native languages, empowering learners to master programming in their mother tongue. Our first offering is an HTML and CSS course in Lezgi, with more courses and languages on the way."
                action={() => window.open('https://chirval.com/docs/HTML/intro', '_blank')}
              />
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6" >
              <ProjectBox
                img={ProjectImg8}
                title="Translit App: Mapping the Lezgi Alphabets"
                // title="Mobile offline dictionary apps (Android and iOS)"
                text="Explore how the Lezgi language evolved across Cyrillic, Latin, Arabic Ajami, and the native Caucasian Albanian script. This tool offers a clear side-by-side overview and transliteration guide to help preserve and understand Lezgi’s rich linguistic heritage."
                action={() => window.open('https://translit.app/', '_blank')}
              />
            </div>
          </div>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6" style={{ display: 'flex', alignItems: 'end'}}>
              <ProjectBox
                img={ProjectImg4}
                title="Lezgi Numbers libraries"
                text="JavaScript and Java libraries for converting numbers to Lezgi numerals and vice versa, including a Text-To-Speech module. These modules are used on our website and in our Telegram bot."
                action={() => window.open('https://www.npmjs.com/package/lezgi-numbers', '_blank')}
              />
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6" style={{ display: 'flex', alignItems: 'end'}}>
              <ProjectBox
                img={ProjectImg5}
                title="Parsers"
                text="Document parsers that convert scanned dictionary books into detailed data structures, designed with extensibility in mind for all languages."
                action={() => window.open('https://www.github.com/LekiTech', '_blank')}
              />
            </div>
          </div>
          <div className="row flexCenter">
            <div style={{ margin: "50px 0", width: "200px" }}>
              {/* <FullButton title="Load More" action={() => alert("clicked")} /> */}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="lightBg">
        <div className="container">
          <Advertising className="flexSpaceCenter">
            <AddLeft>
              <AddLeftInner>
                <ImgWrapper className="flexCenter">
                  <img className="radius8" src={AddImage2} alt="add" />
                </ImgWrapper>
              </AddLeftInner>
            </AddLeft>
            <AddRight>
              <h4 className="font15 semiBold">A few words about company</h4>
              <h2 className="font40 extraBold">A Study of Creativity</h2>
              <p className="font12">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed
                diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
              </p>
              <ButtonsRow className="flexNullCenter" style={{ margin: "30px 0" }}>
                <div style={{ width: "190px" }}>
                  <FullButton title="Get Started" action={() => alert("clicked")} />
                </div>
                <div style={{ width: "190px", marginLeft: "15px" }}>
                  <FullButton title="Contact Us" action={() => alert("clicked")} border />
                </div>
              </ButtonsRow>
            </AddRight>
          </Advertising>
        </div>
      </div> */}
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  padding: 100px 0;
  margin: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;
const AddLeftInner = styled.div`
  width: 100%;
  position: absolute;
  top: -300px;
  left: 0;
  @media (max-width: 1190px) {
    top: -250px;
  }
  @media (max-width: 920px) {
    top: -200px;
  }
  @media (max-width: 860px) {
    order: 1;
    position: relative;
    top: -60px;
    left: 0;
  }
`;
const ImgWrapper = styled.div`
  width: 100%;
  padding: 0 15%;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;
